import React from 'react';

import { Header } from '@app/components/Header';
import { WelcomeScreen } from '@app/components/WelcomeScreen';
import { Chain } from '@app/components/Chain';
import { AboutAshes } from '@app/components/AboutAshes';
import { StagesList } from '@app/components/StagesList';
import { Dashboards } from '@app/components/Dashboards';
import { Pricing } from '@app/components/Pricing';
import { AboutUs } from '@app/components/AboutUs';
import { Footer } from '@app/components/Footer';

import '@app/scss/style.scss';

export function App() {
	return (
		<div className="app">
			<Header />
			<WelcomeScreen />
			<AboutAshes />
			<Chain />
			<StagesList />
			<Dashboards />
			<Pricing />
			<AboutUs />
			<Footer />
		</div>
	);
}
