/* eslint-disable max-len */
import * as React from 'react';

import '@app/scss/dashboards.scss';
import { socialButton } from '@app/utils/texts/socialButton';
import dashboard from '@app/image/dashboards/dashboard.png';
import dashboardVariants from '@app/image/dashboards/dashboardVariants.png';

import tabs from '@app/image/tabs.svg';

export function Dashboards() {
	return (
		<div className="dashboards">
			<div className="dashboards__top-card card">
				<img src={dashboard} alt="" className="dashboards__image dashboards__top-image" />
				<div className="dashboards__tab text">
					<img src={tabs} alt="tab" className="dashboards-icon" />
					<h3 className="dashboards__subtitle block-subtitle">Dashboards</h3>
					<p className="dashboards__paragraph">Unique dashboards by user to help with work efficiencies and proper pet status evaluations</p>
				</div>
			</div>
			<div className="dashboards__bottom-card card">
				<div className="dashboards__tab text">
					<img src={tabs} alt="tab" className="dashboards__icon" />
					<h3 className="dashboards__subtitle block-subtitle">Access from anywhere</h3>
					<p className="dashboards__paragraph">Wing's web based system can be accessed from desktop, phone or iPads to allow operations even while on the road.</p>
					<div className="dashboards__social-btn">
						{socialButton.map((item) => (
							<a className="social" key={item.icon} href={item.link}>
								<img src={item.icon} alt={item.text} className="social__icon" />
								<img src={item.text} alt={item.text} className="social__icon-app" />
							</a>
						))}
					</div>
				</div>
				<img src={dashboardVariants} alt="tab" className="dashboards__image dashboards__bottom-image" />
			</div>
		</div>
	);
}
