import * as React from 'react';

import { FormikErrors, FormikTouched } from 'formik';

import {
	fieldRow,
	FormValues,
	Item,
} from '@app/components/objects/Demo';
import { InputField } from '@app/components/UI/InputField';

interface TurnoverInfoProps {
	touched: FormikTouched<FormValues>;
	errors: FormikErrors<FormValues>;
}

export function TurnoverInfo(props: TurnoverInfoProps) {
	const { errors, touched } = props;

	return (
		<>
			{fieldRow.map((item: Item) => (
				<InputField
					item={item}
					error={errors[item.name]}
					isTouched={touched[item.name]}
					key={item.name}
				/>
			))}
		</>
	);
}
