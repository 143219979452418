import * as React from 'react';

interface Message {
	type: string,
	icon: string,
	text: string,
}

interface OwnProps {
	open: boolean;
	message: Message;
}

export function Notification(props: OwnProps) {
	const { open, message } = props;

	return (
		<div className={`popup ${open && 'popup_opened'}`}>
			<img src={message.icon} alt={message.type} className="popup__icon" />
			<p className="popup__message text">
				{message.text}
			</p>
		</div>
	);
}
