import * as React from 'react';

import { chainTexts } from '@app/utils/texts/chain';
import { Anchor } from '@app/components/UI/Anchor';
import { Anchors } from '@app/types/Anchors';

import tabs from '@app/image/tabs.svg';
import demoScreens from '@app/image/chain/screen-group.png';

import '@app/scss/chain.scss';

export function Chain() {
	return (
		<Anchor.Tag anchor={Anchors.Chain}>
			<div className="chain">
				<div className="chain__text text">
					<h2 className="chain__title block-title">Chain of Custody Features:</h2>
					{chainTexts.map((item) => (
						<div className="chain__tab" key={item.title}>
							<img src={tabs} alt="tab" className="icon-tab" />
							<h3 className="chain__subtitle block-subtitle">{item.title}</h3>
							<p className="chain__paragraph">{item.text}</p>
						</div>
					))}
				</div>
				<img src={demoScreens} alt="Demo screens" className="chain__group-image" />
			</div>
		</Anchor.Tag>
	);
}
