import * as React from 'react';

type EventHandler<K extends keyof DocumentEventMap> = (this: Document, ev: DocumentEventMap[K]) => void;

export function useEventListener<K extends keyof DocumentEventMap>(event: K, handler: EventHandler<K>): void {
	React.useEffect(() => {
		document.addEventListener(event, handler);

		return () => document.removeEventListener(event, handler);
	}, [event, handler]);
}
