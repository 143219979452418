import * as React from 'react';

import { DemoForm } from '@app/components/DemoForm';
import { Anchor } from '@app/components/UI/Anchor';
import { Anchors } from '@app/types/Anchors';

import iguana from '@app/image/Iguana.svg';
import money from '@app/image/money.svg';

import '@app/scss/pricing.scss';

export function Pricing() {
	return (
		<Anchor.Tag anchor={Anchors.Pricing}>
			<div className="pricing">
				<img src={iguana} alt="Iguana" className="pricing__iguana" />
				<div className="pricing__text text">
					<img src={money} alt="Money" className="pricing__money" />
					<h2 className="pricing__title block-title">Pricing</h2>
					<p className="pricing__paragraph">We made this simple! Pay month to month with no annual contract</p>
				</div>
				<DemoForm />
			</div>
		</Anchor.Tag>
	);
}
