import * as React from 'react';

import { FIRST_PARAGRAPH, photos, SECOND_PARAGRAPH } from '@app/utils/texts/aboutUsText';
import { Anchor } from '@app/components/UI/Anchor';
import { Anchors } from '@app/types/Anchors';

import '@app/scss/aboutUs.scss';

export function AboutUs() {
	return (
		<Anchor.Tag anchor={Anchors.About}>
			<div className="about">
				<div className="about__text text">
					<h2 className="about__title block-title">About Us:</h2>
					<div className="about__description">
						<p className="about__paragraph">{FIRST_PARAGRAPH}</p>
						<p className="about__paragraph">{SECOND_PARAGRAPH}</p>
					</div>
				</div>
				<div className="about__photos">
					{photos.map((item) => (
						<figure className="about__photo_block" key={item.name}>
							<img src={item.link} alt={item.name} className="about__photo" />
							<figcaption className="text">{item.name}</figcaption>
						</figure>
					))}
				</div>
			</div>
		</Anchor.Tag>
	);
}
