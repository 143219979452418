import * as React from 'react';

import { ResponseError } from '@app/components/objects/errors/ResponseError';
import { baseRequest } from '@app/utils/api';

export interface RequestConfig {
	requestOnMount: boolean;
	cancelOnUnmount?: boolean;
}

type LoadFunction<TItem, TData> = (data?: TData) => Promise<TItem>;

export interface RequestData<TData, TItem> {
	loading: boolean;
	error: string | null;
	code: number;
	item: TItem | null;

	reload: LoadFunction<TItem, TData>;
	cancel: () => void;
}

const defaultConfig: RequestConfig = {
	requestOnMount: true,
	cancelOnUnmount: true,
};

const abortMessage = 'The user aborted a request.';
function toResponseError(msg: string | ResponseError): ResponseError {
	if (typeof msg === 'string') return new ResponseError({ message: msg, code: -1 });

	return msg;
}

export function useRequest<TItem, TData>(
	endpoint: string,
	data: TData | undefined = undefined,
	config: RequestConfig = defaultConfig,
): RequestData<TData, TItem> {
	const [item, setItem] = React.useState<TItem | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const [code, setCode] = React.useState<number>(0);

	// eslint-disable-next-line compat/compat
	const abortController = new AbortController();
	const cancel = () => abortController.abort();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const load: LoadFunction<TItem, TData> = (data?: TData) => {
		setItem(null);
		setError(null);
		setLoading(true);

		const task = baseRequest<TItem, TData>(endpoint, data);
		task.then((item: TItem) => {
			setItem(item);
			setLoading(false);
			setCode(0);
		})
			.catch((error: string | ResponseError) => {
				const content = toResponseError(error);
				if (content.message !== abortMessage) {
					setError(content.message);
					setLoading(false);
					setCode(content.code);
				}
			});

		return task;
	};

	React.useEffect(() => {
		if (config.requestOnMount) {
			load(data);
		}

		return () => {
			if (config.cancelOnUnmount) cancel();
		};
	}, []);

	return {
		loading,
		error,
		code,
		item,

		reload: load,
		cancel,
	};
}
