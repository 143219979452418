import apple from '@app/image/dashboards/apple.svg';
import googlePlay from '@app/image/dashboards/googlePlay.svg';
import appStore from '@app/image/dashboards/appStore.svg';
import google from '@app/image/dashboards/google.svg';

export const socialButton = [
	{
		icon: apple,
		text: appStore,
		link: 'https://apps.apple.com/us/app/wingstracker/id1564960755',
	},
	{
		icon: googlePlay,
		text: google,
		link: 'https://play.google.com/store/apps/details?id=com.sgsdt.wings',
	},
];
