import * as React from 'react';

import { Field, FieldProps } from 'formik';
import InputMask from 'react-input-mask';

import {
	addErrorStyle,
	Item,
} from '@app/components/objects/Demo';
import { clsx } from '@app/utils/clsx';

interface InputFieldProps {
	item: Item;
	// eslint-disable-next-line react/no-unused-prop-types
	isTouched?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	error?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	setFieldValue?: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
}

interface CellProps {
	data: InputFieldProps;
	style: string;
}

function DivCell(props: CellProps) {
	const { data, style } = props;
	const {
		item, isTouched, error, setFieldValue,
	} = data;

	return (
		<div className={`demo__fields ${item.class} ${item.as === 'textarea' && 'demo__notes'}`} key={item.name}>
			<label htmlFor={`form-${item.name}`}>{item.label}</label>
			<div className={`field ${item.as && addErrorStyle}`}>
				{item.type === 'tel'
					? (
						<Field
							name={item.name}
							label="Phone Number"
							className={`${addErrorStyle(error && isTouched)} ${item.as ? '' : style}`}
						>
							{({ field }: FieldProps) => (
								<InputMask
									{...field}
									mask="+1(999)999-9999"
									type={item.type}
									className={`${addErrorStyle(error && isTouched)} ${item.as ? '' : style}`}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setFieldValue?.(
											item.name,
											e.target.value,
										);
									}}
								/>
							)}
						</Field>
					)
					: (
						<Field
							type={item.type}
							name={item.name}
							as={item.as}
							className={`${addErrorStyle(error && isTouched)} ${item.as ? '' : style}`}
						/>
					)}
				{isTouched && error && <div className="error">{error}</div>}
			</div>
		</div>
	);
}

function WithOutDivCell(props: CellProps) {
	const { data, style } = props;
	const { item, isTouched, error } = data;

	return (
		<>
			<label htmlFor={`form-${item.name}`}>{item.label}</label>
			<div className={`field ${item.as && addErrorStyle}`}>
				<Field
					type={item.type}
					name={item.name}
					className={clsx(addErrorStyle(error && isTouched), !item.as && style)}
				/>
				{isTouched && error && <div className="error">{error}</div>}
			</div>
		</>
	);
}

export function InputField(props: InputFieldProps) {
	const style = props.item.class && !props.item.as ? 'demo-row_field' : '';

	if (props.item.class) return <WithOutDivCell data={props} style={style} />;

	return (<DivCell data={props} style={style} />);
}
