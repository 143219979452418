import React from 'react';
import CoreSelect, { Option } from 'rc-select';

import 'rc-select/assets/index.css';
import '@app/scss/select.scss';

import { ReactComponent as Arrow } from '@app/image/select-arrow.svg';

export interface SelectOption {
	title: string;
	value: string | number;
}

interface SelectProps {
	title: string;
	options: Array<SelectOption>;
	loading?: boolean;
	disabled?: boolean;
	value?: number;
	onChange: (value: number) => void;
	onSearch?: (query: string) => void;
}
export function Select(props: SelectProps) {
	const {
		title, options, loading, onSearch, disabled, onChange, value,
	} = props;

	return (
		<div className="select">
			<label htmlFor="core-select">{title}</label>
			<CoreSelect
				className=""
				id="core-select"
				value={value}
				onChange={(value) => onChange(value)}
				onSearch={(query: string) => onSearch?.(query)}
				showSearch
				suffixIcon={<Arrow />}
				allowClear
				removeIcon
				loading={loading}
				disabled={disabled}
			>
				{options.map((option) => <Option key={option.value} value={option.value}>{option.title}</Option>)}
			</CoreSelect>
		</div>
	);
}
