import React from 'react';
import { useFormikContext } from 'formik';

import { FormValues } from '@app/components/objects/Demo';
import { InputField } from '@app/components/UI/InputField';
import { Select } from '@app/components/UI/Select';
import { useRequest } from '@app/hooks/useRequest';
import { Country } from '@app/components/objects/Country';
import { List } from '@app/components/objects/List';
import { State } from '@app/components/objects/State';

interface Entity {
	name: string;
	id: number;
}

interface RequestParams {
	count: number,
	offset?: number;
	filters: {
		search: string,
		country?: string;
	},
}
const count = 100;
const getOptions = <T extends Entity>(item: T) => ({ title: item.name, value: item.id });

export function AddressInfo() {
	const {
		errors, touched, setFieldValue, values,
	} = useFormikContext<FormValues>();
	const [countries, setCountries] = React.useState<Array<Country>>([]);
	const [states, setStates] = React.useState<Array<State>>([]);
	const countryRef = React.useRef<null | Country>(null);
	const countryRequest = useRequest<List<Country>, RequestParams>('countryList', undefined, { requestOnMount: false });
	const stateRequest = useRequest<List<State>, RequestParams>('stateList', undefined, { requestOnMount: false });

	React.useEffect(() => {
		if (countryRequest.error) return;

		if (!countryRequest.item?.list.length && !countryRequest.loading) {
			countryRequest.reload({ count, offset: 0, filters: { search: '' } })
				.then((res) => setCountries(res.list));
		}
	}, [countryRequest.item?.list, countryRequest.loading, countryRequest.error]);

	React.useEffect(() => {
		if (!values.countryId) return;

		stateRequest.reload({ count, offset: 0, filters: { search: '', country: countryRef.current?.alpha3 } })
			.then((res) => setStates(res.list));
	}, [values.countryId]);

	return (
		<>
			<Select
				title="Country"
				value={values.countryId}
				options={countries.map(getOptions)}
				loading={countryRequest.loading}
				onChange={(value: number) => {
					if (!value) setFieldValue('stateId', undefined);
					setFieldValue('countryId', value);
					countryRef.current = countries.find((item) => item.id === value) ?? null;
				}}
				onSearch={(query: string) => {
					countryRequest.reload({ count, filters: { search: query } })
						.then((res) => setCountries(res.list));
				}}
			/>
			<Select
				title="State"
				value={values.stateId}
				options={states.map(getOptions)}
				loading={stateRequest.loading}
				disabled={!values.countryId}
				onChange={(value: number) => setFieldValue('stateId', value)}
				onSearch={(query: string) => {
					stateRequest.reload({ count, filters: { search: query, country: countryRef.current?.alpha3 } })
						.then((res) => setStates(res.list));
				}}
			/>
			<InputField
				item={{
					name: 'city',
					type: 'text',
					label: 'City',
				}}
				error={errors.city}
				isTouched={touched.city}
				setFieldValue={setFieldValue}
				key="city"
			/>
		</>
	);
}
