import { mary, nikita } from '@app/image/aboutUs/index';

export const FIRST_PARAGRAPH = `
Wings was created by the trio Allan Gardner, Nikita Pavlov and veterinarian Dr Mary Gardner.
All three have over two decades of software design experience each plus expertise in the pet end of life industry as
the co-founders of Monarch Pet Memorial Services - the first aquamation service in South Florida.
`;
export const SECOND_PARAGRAPH = `
Dr. Mary Gardner also lectures internationally on best practices for geriatric veterinary medicine,
euthanasia and aftercare. They love pets, they love creating software and they love helping those with the same mission
- providing excellent end of life experiences for families that love their pets.
`;

export const photos = [
	{
		name: 'Dr Mary Gardner',
		link: mary,
	},
	{
		name: 'Nikita Pavlov',
		link: nikita,
	},
];
