import * as React from 'react';

import '@app/scss/aboutAshes.scss';
import cat from '@app/image/aboutAshes/cat.svg';
import { FIRST_PARAGRAPH, SECOND_PARAGRAPH, THIRD_PARAGRAPH } from '@app/utils/texts/ashesText';

export function AboutAshes() {
	return (
		<div className="ashes">
			<img src={cat} alt="cat" className="ashes__image" />
			<div className="ashes__text text">
				<h2 className="ashes__title block-title">"How will I know it is my pet's ashes?"</h2>
				<p className="ashes__paragraph">{FIRST_PARAGRAPH}</p>
				<p className="ashes__paragraph">{SECOND_PARAGRAPH}</p>
				<p className="ashes__paragraph">{THIRD_PARAGRAPH}</p>
			</div>
		</div>
	);
}
