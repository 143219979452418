import done from '@app/image/success.svg';
import fault from '@app/image/error.svg';

export const typeError = 'This field is required';

export enum TypeOfCremation {
	Aquamation = 'Aquamation',
	Fire = 'Fire',
	Both = 'Both',
}

export enum TypeOfCremationNumber {
	Aquamation = 1,
	Fire = 2,
	Both = 0,
}

export interface FormValues {
	name: string;
	email: string;
	phone: string;
	crematory: string;
	typeOfCrematory: number;
	petsCount: number;
	clinicsCount: number;
	notes: string;
	contactTime: string;

	countryId?: number;
	city: string;
	stateId?: number;
}

export interface Item {
	name: keyof FormValues;
	type: string;
	label: string;
	class?: string;
	as?: string;
}

export const fields: Array<Item> = [
	{
		name: 'name',
		type: 'text',
		label: 'Name',
	},
	{
		name: 'email',
		type: 'email',
		label: 'Email adress',
	},
	{
		name: 'phone',
		type: 'tel',
		label: 'Phone number',
	},
	{
		name: 'crematory',
		type: 'text',
		label: 'Crematory Name',
	},
];

export const fieldRow: Array<Item> = [
	{
		name: 'petsCount',
		type: 'number',
		label: 'How many pets do you currently process per month:',
		class: 'demo-row',
	},
	{
		name: 'clinicsCount',
		type: 'number',
		label: 'How many clinics do you service:',
		class: 'demo-row',
	},
	{
		name: 'contactTime',
		type: 'text',
		label: 'What days and times are best for you to see a demo:',
		class: 'demo-row',
		as: 'input',
	},
];

export const phoneRegExp = /^((\+[1-9]{1,4})[/\- ]?)?(\(?\d{3}\)?[/\- ]?)?[\d\- ]{8}$/;

export const addErrorStyle = (error?: boolean | ''): string => (error ? 'demo__field demo__field_error' : 'demo__field');

export const successMessage = {
	type: 'Success',
	icon: done,
	text: 'Thank you for submitting your demo request!  We will be in contact shortly to set up your demonstration!',
};

export const errorMessage = {
	type: 'Error',
	icon: fault,
	text: 'Something went wrong!',
};
