import * as React from 'react';

import { Anchor } from '@app/components/UI/Anchor';
import { Anchors } from '@app/types/Anchors';

import browser from '@app/image/welcome/browser2.png';
import message from '@app/image/welcome/message.svg';
import friends from '@app/image/welcome/friends.svg';

import '@styles/welcome.scss';

export function WelcomeScreen() {
	return (
		<div className="welcome-screen">
			<div className="welcome-screen__text-block">
				<h1 className="welcome-screen__title">Web Based Software Exclusive for Pet Crematories</h1>
				<Anchor.Link anchor={Anchors.Demo}>
					<button className="welcome-screen__btn demo-btn" type="button">
						Get a demo
					</button>
				</Anchor.Link>
			</div>
			<div className="welcome-screen__images">
				<img src={browser} alt="browser" className="welcome-screen__browser" />
				<div className="welcome-screen__messages">
					<img src={message} alt="message" className="welcome-screen__message-small" />
					<img src={message} alt="message" className="welcome-screen__message-big" />
				</div>
				<img src={friends} alt="friends" className="welcome-screen__friends" />
			</div>
		</div>
	);
}
