/* eslint-disable compat/compat */
import { FormValues } from '@app/components/objects/Demo';
import { RequestError } from '@app/components/objects/errors/RequestError';
import { ResponseError, ResponseErrorContent } from '@app/components/objects/errors/ResponseError';

interface Result {
	status: number;
	text: string;
	ok: boolean;
}

export function demoRequest(data: FormValues): Promise<string> {
	const baseUrl = process.env.app.api;

	const result: Result = {
		status: -1,
		text: '',
		ok: false,
	};

	return fetch(`${baseUrl}/demo-request`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then((response: Response) => {
			result.status = response.status;
			result.ok = response.ok;

			return response.text();
		})
		.then((text: string) => {
			if (!result.ok) throw new RequestError(result.status, text);

			return text;
		});
}

export function baseRequest<T, TData>(type: string, data?: TData): Promise<T> {
	const baseUrl = process.env.app.api;
	const headers: HeadersInit = {
		'Content-type': 'application/json; charset=utf-8',
	};

	return fetch(`${baseUrl}/api/post`, {
		credentials: 'same-origin',
		method: 'POST',
		headers,
		body: JSON.stringify({
			type,
			data: JSON.stringify(data),
		}),
	})
		.then((response) => response.json())
		.then((data) => {
			if (!data.success) throw new ResponseError(data.response as ResponseErrorContent);

			return data.response as T;
		});
}
