import * as React from 'react';

import { FormikErrors, FormikTouched } from 'formik';

import {
	fields,
	FormValues,
	Item,
} from '@app/components/objects/Demo';
import { InputField } from '@app/components/UI/InputField';

interface OwnerInfoProps {
	touched: FormikTouched<FormValues>;
	errors: FormikErrors<FormValues>;
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
}

export function OwnerInfo(props: OwnerInfoProps) {
	const { errors, touched, setFieldValue } = props;

	return (
		<>
			{fields.map((item: Item) => (
				<InputField
					item={item}
					error={errors[item.name]}
					isTouched={touched[item.name]}
					setFieldValue={setFieldValue}
					key={item.name}
				/>
			))}
		</>
	);
}
