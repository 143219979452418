/* eslint-disable max-len */
import {
	arrow, hand, track, plus, urn, invoice,
} from '@app/image/chain/icon/index';

export const chainTexts = [
	{
		title: 'Clinic Access ',
		text: 'Create accounts for all clinics serviced with allowing them easy data entry and realtime tracking via our user friendly portal. Identification tags are generated to be printed containing unique QR codes for proper handling.',
	},
	{
		title: 'Pet Receiving',
		text: 'Pet pick up (and ashes drop off) sheets automatically generated for quick service. The use of QR codes makes receiving pets into custody easier and more reliable.',
	},
	{
		title: 'Pet Tracking',
		text: 'Each step along the journey, the pet\'s QR code can be scanned and status updates are made efficient - tracking the user, date and time for each step in the process for accountability. ',
	},
];

export const stages = [
	{
		title: 'Journey Assurance',
		text: 'You set the journey protocols unique to your process and assure that pets follow the proper steps in the process, including the assurance that all items in previous steps are performed before moving forward.',
		icon: arrow,
	},
	{
		title: 'Customizable Remembrance Packaging',
		text: 'Default remembrance packages by clinic with unique pricing by service type is available. Additions or changes to remembrance items can be made during pet entry and confirmed during the process.',
		icon: hand,
	},
	{
		title: 'Aquamation Machine Tracking',
		text: 'If you use Aquamation, Wings is built to track machine, bin location and chemical uses per load; also allowing you to journal any unique notes about each run.',
		icon: track,
	},
	{
		title: 'Clinic Status Tracking',
		text: 'All throughout the process, veterinary clinics can log in to see where along the process the pet is (statuses are customizable to each crematory). This will reduce questions about pet status as well as calls inquiring whereabouts.',
		icon: plus,
	},
	{
		title: 'Inventory Tracking and Forecasting',
		text: 'Recording of urns used for each individual cremation allows a crematory to not only track what is used but develop forecasting reports to ensure proper inventory levels. For aquamation operations, chemical tracking is also available.',
		icon: urn,
	},
	{
		title: 'Invoicing and Bookkeeping',
		text: 'Invoicing for each clinic is available and can be done as often as a crematory would like. Veterinary clinic managers can also log in to see their running total to help them with budgeting.',
		icon: invoice,
	},
];
