import * as React from 'react';

import {
	FormikProps, Form, Formik,
} from 'formik';
import * as Yup from 'yup';

import { RadioGroup } from '@app/components/UI/RadioGroup';
import {
	errorMessage,
	FormValues,
	phoneRegExp,
	successMessage,
	typeError,
	TypeOfCremation,
	TypeOfCremationNumber,
} from '@app/components/objects/Demo';
import { OwnerInfo } from '@app/components/UI/OwnerInfo';
import { TurnoverInfo } from '@app/components/UI/TurnoverInfo';
import { demoRequest } from '@app/utils/api';
import { clsx } from '@app/utils/clsx';

import { Anchor } from '@app/components/UI/Anchor';
import { Anchors } from '@app/types/Anchors';
import { Notification } from '@app/components/UI/Notification';

import loader from '@app/image/loader.gif';

import '@app/scss/demo.scss';
import { RequestError } from '@app/components/objects/errors/RequestError';
import { AddressInfo } from '@app/components/UI/AddressInfo';
import { InputField } from '@app/components/UI/InputField';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email is not valid').required('Email is required'),
	name: Yup.string().required('Name is required'),
	phone: Yup.string()
		.matches(phoneRegExp, 'Invalid format')
		.required('Phone number is required'),
	crematory: Yup.string().required('Crematory Name is required'),
	typeOfCrematory: Yup.mixed<TypeOfCremation>()
		.oneOf(Object.values(TypeOfCremation), 'A radio option is required')
		.required('A radio option is required'),
	petsCount: Yup.number().typeError('There should be a number here').min(0, 'This value should be positive').required(typeError),
	clinicsCount: Yup.number().typeError('There should be a number here').min(0, 'This value should be positive').required('This field is required'),
	contactTime: Yup.string().required(typeError),
	notes: Yup.string(),
});

const getData = (values: FormValues) => {
	return {
		...values,
		typeOfCrematory: Number(TypeOfCremationNumber[values.typeOfCrematory]),
		offset: -(new Date().getTimezoneOffset()),
	};
};

export function DemoForm() {
	const [success, setSuccess] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>('');
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const submitBtnText = !success ? 'Submit' : 'Request Sent';

	React.useEffect(() => {
		setTimeout(() => {
			setIsOpen(false);
		}, 7500);
	}, [isOpen]);

	return (
		<Anchor.Tag anchor={Anchors.Demo}>
			<div className="demo">
				<h1 className="demo__title block-title">Get a Demo</h1>
				<Formik<FormValues>
					initialValues={{
						name: '',
						email: '',
						phone: '',
						crematory: '',
						typeOfCrematory: 0,
						petsCount: 0,
						clinicsCount: 0,
						notes: '',
						contactTime: '',
						countryId: undefined,
						stateId: undefined,
						city: '',
					}}
					onSubmit={(values, { setSubmitting }) => {
						const data: FormValues = getData(values);
						setSubmitting(true);
						demoRequest(data)
							.then(() => {
								setIsOpen(true);
								setSuccess(true);
							})
							.catch((error: RequestError) => {
								setIsOpen(true);
								setError(error.message);
							})
							.finally(() => setSubmitting(false));
					}}
					validationSchema={validationSchema}
				>
					{(props: FormikProps<FormValues>) => {
						const {
							touched,
							errors,
							isSubmitting,
							setFieldValue,
						} = props;

						return (
							<Form onSubmit={props.handleSubmit}>
								<div className="demo__form form">
									<OwnerInfo touched={touched} errors={errors} setFieldValue={setFieldValue} />
									<RadioGroup touched={touched} errors={errors} />
									<TurnoverInfo touched={touched} errors={errors} />
									<AddressInfo />
									<InputField
										item={{
											name: 'notes',
											type: 'text',
											label: 'Additional Notes:',
											as: 'textarea',
										}}
									/>
								</div>
								<div className="submit-block">
									<button
										type="submit"
										disabled={isSubmitting || success}
										className={clsx('demo-btn demo__submit', success && 'demo__submit_success')}
									>
										<img src={loader} alt="loader" className={clsx('demo__loader', isSubmitting && 'demo__loader_visible')} />
										{!isSubmitting && submitBtnText}
									</button>
								</div>
							</Form>
						);
					}}
				</Formik>

				<Notification open={isOpen} message={success ? successMessage : { ...errorMessage, text: error ?? errorMessage.text }} />
			</div>
		</Anchor.Tag>
	);
}
