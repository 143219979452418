import * as React from 'react';

import '@app/scss/footer.scss';

export function Footer() {
	return (
		<footer className="footer">
			<p className="footer__caption">Get in touch:</p>
			<a href="/" className="footer__link">Support@wingssoftware.com</a>
		</footer>
	);
}
