import { Field, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';

import { FormValues, TypeOfCremation } from '@app/components/objects/Demo';

interface OwnProps {
	touched: FormikTouched<FormValues>;
	errors: FormikErrors<FormValues>;
}

export function RadioGroup(props: OwnProps) {
	const { touched, errors } = props;

	return (
		<div className="demo__radio">
			<p>Do you use:</p>
			<div>
				<label htmlFor="aquamation">
					<Field type="radio" name="typeOfCrematory" value={TypeOfCremation.Aquamation} />
					Aquamation
				</label>
				<label htmlFor="fire">
					<Field type="radio" name="typeOfCrematory" value={TypeOfCremation.Fire} />
					Fire
				</label>
				<label htmlFor="both">
					<Field type="radio" name="typeOfCrematory" value={TypeOfCremation.Both} />
					Both
				</label>
			</div>
			{touched.typeOfCrematory && errors.typeOfCrematory && <div className="error error-radio">{errors.typeOfCrematory}</div>}
		</div>
	);
}
