import * as React from 'react';

import '@app/scss/stagesList.scss';
import { stages } from '@app/utils/texts/chain';

export function StagesList() {
	return (
		<div className="stages">
			{stages.map((item) => (
				<div className="stages__card text" key={item.icon}>
					<img src={item.icon} alt={item.title} className="stages__icon" />
					<h3 className="stages__subtitle block-subtitle">{item.title}</h3>
					<p className="stages__paragraph">{item.text}</p>
				</div>
			))}
		</div>
	);
}
