import * as React from 'react';

import { EventBus } from '@app/services/EventBus';
import { Nullable } from '@app/types/Utility';

interface AnchorTagProps {
	anchor: string;
	children: React.ReactNode;
	offset?: number;
}

interface AnchorLinkProps {
	anchor: string;
	className?: string;
	children: React.ReactNode;
	offset?: number;
}

interface AnchorOffsetProps {
	anchor: string;
	offset: number;
}

enum AnchorEvent {
	Adjust = 0,
	Navigate = 1,
}

interface AnchorEventData {
	tag: Nullable<string>;
	offset: Nullable<number>;
}

const AnchorEventBus: EventBus<AnchorEvent, AnchorEventData> = new EventBus<AnchorEvent, AnchorEventData>();
type Handler = (event: AnchorEvent, data: AnchorEventData) => void;

// eslint-disable-next-line react/function-component-definition
const AnchorTag: React.FC<AnchorTagProps> = (props: AnchorTagProps) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const handlerRef = React.useRef<Handler>();
	const [offset, setOffset] = React.useState<number>(() => 0);

	handlerRef.current = (event: AnchorEvent, data: AnchorEventData): void => {
		if (event === AnchorEvent.Adjust) {
			if (data.tag !== null && data.tag !== props.anchor) return;

			const newOffset = (props.offset ?? 0) + (data.offset ?? 0);
			setOffset(newOffset);
		} else if (event === AnchorEvent.Navigate) {
			if (data.tag !== props.anchor) return;
			if (ref.current === null) return;

			ref.current?.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};

	React.useEffect(() => {
		if (!handlerRef.current) return () => console.warn('<Anchor> no event handler');

		const id = AnchorEventBus.subscribe(handlerRef.current);

		return () => AnchorEventBus.unsubscribe(id);
	}, []);

	return (
		<>
			<div style={{ position: 'relative' }}>
				<div
					ref={ref}
					id={props.anchor}
					style={{ position: 'absolute', top: -offset }}
				/>
			</div>
			{props.children}
		</>
	);
};

// eslint-disable-next-line react/function-component-definition
const AnchorLink: React.FC<AnchorLinkProps> = (props: AnchorLinkProps) => {
	function handler() {
		AnchorEventBus.publish(AnchorEvent.Navigate, { tag: props.anchor, offset: null });
	}

	React.useEffect(() => {
		AnchorEventBus.publish(AnchorEvent.Adjust, { tag: props.anchor, offset: props.offset ?? 0 });
	}, [props.offset]);

	return (
		<a
			className={props.className}
			onClick={handler}
		>
			{props.children}
		</a>
	);
};

// eslint-disable-next-line react/function-component-definition
const AnchorOffset: React.FC<AnchorOffsetProps> = (props: AnchorOffsetProps) => {
	React.useEffect(() => {
		AnchorEventBus.publish(AnchorEvent.Adjust, { tag: props.anchor, offset: props.offset });
	}, [props.offset]);

	return null;
};

export const Anchor = {
	Tag: AnchorTag,
	Link: AnchorLink,
	Offset: AnchorOffset,
};
