import * as React from 'react';

import { Anchors } from '@app/types/Anchors';
import { Anchor } from '@app/components/UI/Anchor';
import { useDimensions } from '@app/hooks/useDimensions';
import { useEventListener } from '@app/hooks/useEventListener';

import '@app/scss/header.scss';
import logo from '@app/image/logo.svg';

const burgerId = 'burger-menu';

export function Header(): React.ReactElement {
	const [isBurger, setIsBurger] = React.useState<boolean>(false);

	const ref = React.useRef<HTMLElement>(null);
	const dimensions = useDimensions(ref);

	const handler = React.useCallback((event: MouseEvent) => {
		const element = event.target as HTMLElement;

		if (element.id === burgerId) {
			setIsBurger(!isBurger);
			event.stopPropagation();
		} else {
			setIsBurger(false);
		}
	}, [isBurger]);
	useEventListener<'click'>('click', handler);

	return (
		<header className="header" id="header" ref={ref}>
			<Anchor.Offset anchor="demo" offset={dimensions.height} />
			<div className="container">
				<div className="header__row">
					<a href="/" className="header__logo-group">
						<img src={logo} alt="logo" className="header__logo" />
					</a>
					<div className="header__menu menu">
						<div className="menu__icon icon-menu" id={burgerId}>
							<span />
						</div>
						<nav className={`menu__body ${isBurger ? 'active' : ''}`}>
							<ul className="menu__list">
								<li>
									<Anchor.Link
										anchor={Anchors.Chain}
										className="menu__link"
										offset={dimensions.height}
									>
										Chain of Custody Features
									</Anchor.Link>
								</li>
								<li>
									<Anchor.Link
										anchor={Anchors.Pricing}
										className="menu__link"
										offset={dimensions.height}
									>
										Pricing
									</Anchor.Link>
								</li>
								<li>
									<Anchor.Link
										anchor={Anchors.About}
										className="menu__link"
										offset={dimensions.height}
									>
										About Us
									</Anchor.Link>
								</li>
							</ul>
						</nav>
						<button
							type="button"
							className="header__btn"
						>
							<a href={process.env.app.login}>Log in</a>
						</button>
					</div>
				</div>
			</div>
		</header>
	);
}
